@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

header {
  height: 70px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 1em;
  box-sizing: border-box;

  position: sticky;
  top: 0;
  z-index: 100;

  background-color: #0f0e17;
}
a{
  text-decoration: none;
}
header h1 {
  color: #fffffe;
  margin: 0;
}

nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 8px;
  box-sizing: border-box;
  margin-top: 8px;
  margin-bottom: 8px;
}

.fa-solid{
  color: #0f0e17;
  font-size: 2em;
}

.error{
  color: red !important;
}

.home{
  color: #fffffe !important;
}

#content{
  margin: 0 1.5em;
  min-height: 75vh;
  font-family: 'Poppins', sans-serif;
}

/** Card Article **/
.listArticles{
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 0.5em));
  gap: 1em;
}

@media screen and (min-width: 768px) {
  #root{
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  main{
    width: 80%;
  }

  .listArticles{
    display: grid;
    grid-template-columns: repeat(4, 24%);
    gap: 1em;
  }
}

.card {
  position: relative;
  height: 200px;
  width: 100%;
}

.cardTheme{
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background: rgb(236, 236, 236);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}


.image-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card_title{
  position: absolute;
  overflow: hidden;
  word-wrap: break-word;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3.5em;
  margin: 0;
  padding: 8px;
  background: #fffffe;
  display: flex;
  align-items: center;
}
.card_title h1 {
  margin: 0;
  max-height: 100%;
  font-weight: 500;
  font-size: 1em;
}

@media screen and (min-width: 500px){
  .card{
    height: 350px;
  }

  .card_title{
    height: 6em;
  }

  .card_title h1{
    font-weight: 500;
    font-size: 1.5em;
  }

  #content{
    width: 70%;
  }
}

/** filtre **/
#filtresBar{
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  gap: 8px;
  border-top: #0f0e17 solid 1px;
  padding: 1em 0.5em;
  margin-bottom: 8px;
  box-sizing: border-box;
}

.filtre{
  border-radius: 1em;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  cursor: pointer;
  padding: 0.5em 1.5em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease 0s;
  outline: none;
  flex: 1;
  max-width: 150px;
}

.filtre p {
  margin: 0;
  padding: 0;
  align-self: center;
  align-items: center;
}

.filtre_active{
  background-color: #0f0e17;
  color: #fffffe;
}


form{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 2em;
  width: 100%;
  margin-top: 8px;
}

label{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: left;
}

label p{
  margin: 8px;
}

.article{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    gap: 2em;
    width: 100%;
    margin-top: 8px;
}

.article img{
  max-width: 100%;
  max-height: 500px;
  object-fit: contain;

  box-sizing: border-box;
  overflow: hidden;
  text-decoration: none;
}

#article_title{
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 4px;
}
#article_title h1{
  margin: 0;
  text-align: center;
  margin-bottom: 10px;
  border-bottom: #0f0e17 solid 1px;
}

#article_recipe{
  width: inherit;
  min-height: 50px;
  padding: 1.2em;

  box-sizing: border-box;
  overflow: hidden;
  text-decoration: none;
}

input, select, textarea {
  font-weight: 500;
  font-size: 1em;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  padding: 8px;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  
  overflow: hidden;
  border: none;
  outline: none;
  border-radius: 15px;
  padding: 1em;
  background-color: #e6e6e6;
  box-shadow: inset 2px 5px 10px rgba(0,0,0,0.3);
  transition: 300ms ease-in-out;
}

span{
  background-color: transparent;
  font-weight: bold;
  padding: 4px;
}

button{
  max-width: 100px;
  font-weight: bold;
  border-radius: 1em;
  background-color: #fffffe;
  border: #0f0e17 solid 1px;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.button_icon{
  border: none !important;
}

button[type="submit"]{
  border-radius: 1em;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.5em 1.5em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease 0s;
  outline: none;
  flex: 1;
  max-width: 150px;
}

.button_confirm{
  background-color: #26c047;
  color: #fffffe;
}

.input_text{
  height: 200px;
  vertical-align: top !important;
  box-sizing: border-box;
}


.invisible{
  display: none !important;
}


.pop_up{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.pop_up_content{
  width: 90%;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background: rgb(236, 236, 236);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* From Uiverse.io by Shoh2008 */ 
.loader {
  width: 48px;
  height: 48px;
  background: #353535;
  display: block;
  margin: 20px auto;
  position: relative;
  box-sizing: border-box;
  animation: rotationBack 1s ease-in-out infinite reverse;
}

.loader::before {
  content: '';
  box-sizing: border-box;
  left: 0;
  top: 0;
  transform: rotate(45deg);
  position: absolute;
  width: 48px;
  height: 48px;
  background: #2e2e2e;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.loader::after {
  content: '';
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  background: rgb(0, 0, 0);
  transform: translate(-50%, -50%);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}