footer {
   height: 150px;
   width: 100%;

   margin-top: 2em;

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   background-color: #0f0e17;
   color: white;
}
.footer_contact {
   display: flex;
   flex-direction: column;
   align-items: center;
}

.footer_links{
   display: flex;
   justify-content: center;
   gap: 2em;
}

.footer_contact p {
   margin: 0;
}